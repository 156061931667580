import * as React from 'react'

function SvgSlackLogo(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			viewBox="0 0 2447.6 2452.5"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipRule="evenodd" fillRule="evenodd">
				<path
					d="M897.4 0C762.1.1 652.6 109.9 652.7 245.2c-.1 135.3 109.5 245.1 244.8 245.2h244.8V245.3C1142.4 110 1032.8.2 897.4 0c.1 0 .1 0 0 0m0 654H244.8C109.5 654.1-.1 763.9 0 899.2c-.2 135.3 109.4 245.1 244.7 245.3h652.7c135.3-.1 244.9-109.9 244.8-245.2.1-135.4-109.5-245.2-244.8-245.3z"
					fill="#36c5f0"
				/>
				<path
					d="M2447.6 899.2c.1-135.3-109.5-245.1-244.8-245.2-135.3.1-244.9 109.9-244.8 245.2v245.3h244.8c135.3-.1 244.9-109.9 244.8-245.3zm-652.7 0v-654C1795 110 1685.5.2 1550.2 0c-135.3.1-244.9 109.9-244.8 245.2v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.3z"
					fill="#2eb67d"
				/>
				<path
					d="M1550.1 2452.5c135.3-.1 244.9-109.9 244.8-245.2.1-135.3-109.5-245.1-244.8-245.2h-244.8v245.2c-.1 135.2 109.5 245 244.8 245.2zm0-654.1h652.7c135.3-.1 244.9-109.9 244.8-245.2.2-135.3-109.4-245.1-244.7-245.3h-652.7c-135.3.1-244.9 109.9-244.8 245.2-.1 135.4 109.4 245.2 244.7 245.3z"
					fill="#ecb22e"
				/>
				<path
					d="M0 1553.2c-.1 135.3 109.5 245.1 244.8 245.2 135.3-.1 244.9-109.9 244.8-245.2V1308H244.8C109.5 1308.1-.1 1417.9 0 1553.2zm652.7 0v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.2v-653.9c.2-135.3-109.4-245.1-244.7-245.3-135.4 0-244.9 109.8-244.8 245.1 0 0 0 .1 0 0"
					fill="#e01e5a"
				/>
			</g>
		</svg>
	)
}

export default SvgSlackLogo
