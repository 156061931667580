import * as React from 'react'

function SvgTrashIconSolid(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.30001 1.39999C6.03487 1.39999 5.79248 1.5498 5.67391 1.78694L5.16738 2.79999H2.80001C2.41341 2.79999 2.10001 3.11339 2.10001 3.49999C2.10001 3.88659 2.41341 4.19999 2.80001 4.19999L2.80001 11.2C2.80001 11.9732 3.42681 12.6 4.20001 12.6H9.80001C10.5732 12.6 11.2 11.9732 11.2 11.2V4.19999C11.5866 4.19999 11.9 3.88659 11.9 3.49999C11.9 3.11339 11.5866 2.79999 11.2 2.79999H8.83263L8.32611 1.78694C8.20753 1.5498 7.96515 1.39999 7.70001 1.39999H6.30001ZM4.90001 5.59999C4.90001 5.21339 5.21341 4.89999 5.60001 4.89999C5.98661 4.89999 6.30001 5.21339 6.30001 5.59999V9.79999C6.30001 10.1866 5.98661 10.5 5.60001 10.5C5.21341 10.5 4.90001 10.1866 4.90001 9.79999V5.59999ZM8.40001 4.89999C8.01341 4.89999 7.70001 5.21339 7.70001 5.59999V9.79999C7.70001 10.1866 8.01341 10.5 8.40001 10.5C8.78661 10.5 9.10001 10.1866 9.10001 9.79999V5.59999C9.10001 5.21339 8.78661 4.89999 8.40001 4.89999Z"
				fill="#A4A4A4"
			/>
		</svg>
	)
}

export default SvgTrashIconSolid
